import { Badge, MantineColor } from "@mantine/core";

interface ITagProps {
  color: MantineColor;
  name: string;
}

function Tag({ color, name }: ITagProps) {
  if (color.slice(0, 1) === "#")
    return (
      <Badge size="md" variant="filled" color={color} styles={{ label: { overflow: "visible", textOverflow: "unset" } }}>
        {name}
      </Badge>
    );
  else
    return (
      <Badge color={color} size="md" variant="filled" styles={{ label: { overflow: "visible", textOverflow: "unset" } }}>
        {name}
      </Badge>
    );
}

export { Tag };
