import { Box, Button, FileButton, Image, LoadingOverlay } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import { IconPhotoPlus } from "@tabler/icons-react";
import React from "react";
import { MediaApi } from "../../apis";
import { message } from "../../utils/message";

export type IFile = {
  uploading: boolean;
  url?: string;
  file: Blob | null;
};

type Props = {
  value?: string | undefined | null;
  error?: string;
  onChange?: (file: IFile) => void;
  size?: number;
  radius?: number;
};

export function ImageUpload({ onChange, error, value, size, radius }: Props) {
  const { classes, cx } = useStyle();
  const [file, setFile] = React.useState<IFile | null>(null);
  const resetRef = React.useRef<() => void>(null);

  const onFileUpload = (blob: Blob | null) => {
    if (!blob) return;
    if (blob.size < 10485760) {
      (async () => {
        try {
          const form = new FormData();
          form.append("file", blob);

          const res = await MediaApi.uploadImage(form);

          if (onChange)
            onChange({
              file: blob,
              url: res.url,
              uploading: false,
            });

          setFile({
            file: blob,
            url: res.url,
            uploading: false,
          });
        } catch (err: any) {
          setFile(null);
          message.error(err.message);
          console.log(err);
        }
      })();

      setFile({
        file: blob,
        uploading: true,
      });
    } else {
      message.error("Файлын хэмжээ хэтэрсэн байна!");

      setFile({
        file: blob,
        uploading: false,
      });
    }
  };

  React.useEffect(() => {
    value &&
      setFile({
        file: null,
        url: value,
        uploading: false,
      });
  }, [value]);

  return (
    <div className={classes.multiImageUpload}>
      <FileButton resetRef={resetRef} onChange={onFileUpload} accept="image/png,image/jpeg">
        {(props) =>
          file ? (
            <Box sx={{ borderRadius: radius ? radius : 4 }} w={size ? size : 80} h={size ? size : 80} className={cx(classes.imageBox)} {...props}>
              <Image
                className={classes.image}
                width={size ? size : 80}
                height={size ? size : 80}
                radius={radius}
                src={file.file ? URL.createObjectURL(file.file) : file.url}
                alt="image"
              />
              <LoadingOverlay visible={file.uploading} opacity={0.3} loaderProps={{ size: "sm" }} />
            </Box>
          ) : (
            <Button
              radius={radius}
              sx={{ width: size ? size : 80, height: size ? size : 80 }}
              variant="light"
              className={cx(classes.uploadButton, error && "error")}
              {...props}>
              <IconPhotoPlus size={20} />
            </Button>
          )
        }
      </FileButton>
    </div>
  );
}

const useStyle = createStyles((theme, _params, getRef) => ({
  multiImageUpload: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginBottom: 5,
    gap: 15,
  },
  imageBox: {
    border: `1px solid ${theme.colors.gray[4]}`,
    display: "flex",
    position: "relative",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    overflow: "hidden",
    borderRadius: "100%",
    boxShadow: theme.shadows.sm,
    "&.selected": {
      borderWidth: 3,
      borderStyle: "solid",
      borderColor: theme.colors.indigo[6],
    },
  },
  image: {
    position: "absolute",
  },
  uploadButton: {
    cursor: "pointer",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    "&.error": {
      backgroundColor: theme.colors.red[1],
      color: theme.colors.red[6],
    },
  },
}));
