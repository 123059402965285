import { DatePickerInput, DateValue } from "@mantine/dates";
import { useField } from ".";

type Props = {
  name: string;
  label?: string;
  disabled?: boolean;
  placeholder: string;
  rightSection?: React.ReactNode;
  nullable?: boolean;
};

export function DatePickerField({ name, label, placeholder, disabled = false, rightSection, nullable }: Props) {
  const { value, error, onChange } = useField(name);

  return (
    <DatePickerInput
      label={label}
      placeholder={placeholder}
      disabled={disabled}
      valueFormat="MM-DD-YYYY"
      error={error}
      clearable={false}
      rightSection={rightSection}
      value={value ? new Date(value) : null}
      onChange={(e: DateValue) => {
        if (e) {
          onChange(new Date(e));
        } else {
          onChange(null);
        }
      }}
      required={nullable ? false : true}
    />
  );
}
