import { MantineTheme, MantineThemeOverride } from "@mantine/core";
import { Global } from "@mantine/emotion";

export const GlobalStyle = () => {
  return (
    <Global
      styles={() => ({
        "*, *::before, *::after": {
          border: "0 solid #e5e7eb",
        },
        a: {
          color: "inherit",
          textDecoration: "inherit",
        },
      })}
    />
  );
};

export const themeConfig: MantineThemeOverride = {
  colors: {
    network: ["#fbedfd", "#f4d6f6", "#eaaaee", "#e07be8", "#d754e1", "#9c24a4", "#82138a", "#680f6e", "#510b56", "#4a0a4e"],
    inventory: ["#f6eeff", "#e7daf7", "#cab1ea", "#ad86dd", "#9562d2", "#854bcb", "#7d3ec9", "#6b31b2", "#5f2aa0", "#52228d"],
  },
  primaryColor: "indigo",
  components: {
    Breadcrumbs: {
      styles: (theme: MantineTheme) => ({
        breadcrumb: {
          color: theme.colors.gray[7],
          fontSize: 15,
        },
      }),
    },
    Table: {
      styles: () => ({
        highlightOnHover: {
          "&:hover": {
            backgroundColor: "red",
          },
        },
      }),
    },
  },
};
