import { IFilter } from "../interfaces/IFilter";
import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const list = async ({ offset, filter }: IFilter) => {
  return httpRequest.get("/usr/role", { offset, filter });
};

export const create = async (data: any) => {
  return httpRequest.post(`/usr/role`, data);
};

export const update = async (id: string, data: any) => {
  return httpRequest.put(`/usr/role/${id}`, data);
};

export const get = async (id: string) => {
  return httpRequest.get(`/usr/role/${id}`);
};
