import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const list = async (data: any) => {
  return httpRequest.get("/usr/user", data);
};
export const create = async (data: any) => {
  return httpRequest.post("/usr/user", data);
};
export const update = async (id: string, data: any) => {
  return httpRequest.put(`/usr/user/${id}`, data);
};

export const get = async (id: string) => {
  return httpRequest.get(`/usr/user/${id}`);
};

export const remove = async (id: string) => {
  return httpRequest.del(`/usr/user/${id}`);
};

export const updateAvatar = async (id: string, data: any) => await httpRequest.put(`/usr/user/${id}/avatar`, data);

export const roleList = async (data: any) => {
  return httpRequest.get("/usr/user/role", data);
};

export const roleAdd = async (data: any) => {
  return httpRequest.post("/usr/user/role", data);
};

export const roleUpdate = async (id: string, data: any) => {
  return httpRequest.put(`/usr/user/role/${id}`, data);
};

export const useageType = async (id: string, data: { usageType: string }) => {
  return httpRequest.put(`/usr/user/${id}/usage_type`, data);
};

export const send = async (id: string) => {
  return httpRequest.put(`/usr/user/${id}/send`);
};
