import { IFilter } from "../interfaces/IFilter";
import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

//:File -> bank

export const bank_list = async ({ offset, filter }: IFilter) => {
  return httpRequest.get("/usr/finance_settings/bank", { offset, filter });
};

//:File -> Finance-role
export const assign = async (data: { bank: string; userId: string }) => {
  return httpRequest.put(`/usr/finance_settings/finance_role/assign`, data);
};

export const bank_select = async (data: { query: string; limit: number }) => {
  return httpRequest.get("/usr/finance_settings/finance_role/bank_select", data);
};

export const create = async (data: { bank: string }) => {
  return httpRequest.post("/usr/finance_settings/finance_role", data);
};

export const user_select = async (data: { query: string; limit: number }) => {
  return httpRequest.get("/usr/finance_settings/finance_role/user_select", data);
};

export const role_list = async ({ offset, filter }: IFilter) => {
  return httpRequest.get("/usr/finance_settings/finance_role", { offset, filter });
};
