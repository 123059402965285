import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import { ActionIcon, DefaultMantineColor, Flex, Tooltip } from "@mantine/core";
import { IconFileDots } from "@tabler/icons-react";

type IExtra = {
  text: string;
  icon: ReactJSXElement;
  key: string;
  color?: DefaultMantineColor;
};

type Props = {
  extra: IExtra[];
  onClick: (key: string) => void;
  hideKeys?: string[] | undefined[] | null[];
};

export function RowActionIcon({ extra = [], onClick, hideKeys }: Props) {
  return (
    <Flex align="center" gap="xs">
      {extra
        .filter((item) => !hideKeys?.some((key: string | undefined | null) => key === item.key))
        .map((item, index) => {
          return (
            <Tooltip
              onClick={() => onClick(item.key)}
              key={index}
              label={item.text}
              transitionProps={{ duration: 150, exitDuration: 150, transition: "pop-top-left" }}
              arrowPosition="center"
              withArrow>
              <ActionIcon variant="light" color={item.color || ""}>
                {item.icon || <IconFileDots />}
              </ActionIcon>
            </Tooltip>
          );
        })}
    </Flex>
  );
}
