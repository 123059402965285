import { IFilter } from "../interfaces/IFilter";
import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const list = async ({ offset, filter }: IFilter) => {
  return httpRequest.get("/usr/approval_rule", { offset, filter });
};

export const create = async (data: {
  businessId: string;
  branchId: string;
  module: string;
  systemAction: string;
  name: string;
  description: string;
  levels: any;
}) => {
  return httpRequest.post("/usr/approval_rule", data);
};
