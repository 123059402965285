import { createSlice } from "@reduxjs/toolkit";
import { IGeneral } from "../interfaces/IGeneral";

const initialState: IGeneral = {
  businessTypes: [],
  departmentUnits: [],
  employeeUnits: [],
  roles: [],
  socialTypes: [],
  userRegisterStatus: [],
  userStatus: [],
  businesses: [],
  actions: [],
  modules: [],
  users: [],
  branches: [],
  systemUsageTypes: [],
  accessLevels: [],
  roleTypes: [],
  permissions: [],
  userRoleTypes: [],
  roleStatus: [],
  bankNames: [],
  financeRoleStatus: [],
  financeRoleBankStatus: [],
};

const generalSlice = createSlice({
  name: "general",
  initialState,
  reducers: {
    initGeneral: (state, { payload }: { payload: IGeneral }) => {
      const {
        businessTypes,
        departmentUnits,
        employeeUnits,
        roles,
        socialTypes,
        userRegisterStatus,
        userStatus,
        businesses,
        actions,
        modules,
        users,
        branches,
        systemUsageTypes,
        accessLevels,
        roleTypes,
        permissions,
        userRoleTypes,
        roleStatus,
        bankNames,
        financeRoleStatus,
        financeRoleBankStatus,
      } = payload;
      state.businessTypes = businessTypes;
      state.roles = roles;
      state.departmentUnits = departmentUnits;
      state.employeeUnits = employeeUnits;

      state.socialTypes = socialTypes;
      state.userRegisterStatus = userRegisterStatus;
      state.userStatus = userStatus;
      state.businesses = businesses;
      state.actions = actions;
      state.modules = modules;
      state.users = users;
      state.branches = branches;
      state.systemUsageTypes = systemUsageTypes;
      state.accessLevels = accessLevels;
      state.branches = branches;
      state.roleTypes = roleTypes;
      state.permissions = permissions;
      state.userRoleTypes = userRoleTypes;
      state.roleStatus = roleStatus;
      state.actions = actions;
      state.bankNames = bankNames;
      state.financeRoleStatus = financeRoleStatus;
      state.financeRoleBankStatus = financeRoleBankStatus;

      return state;
    },
  },
});

export const generalReducer = generalSlice.reducer;

export const { initGeneral } = generalSlice.actions;
