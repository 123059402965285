import { AppShell, Box } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import { useScreenDetector } from "../screen-detector";
import { Breadcrumb } from "./breadcrumb";
import { Header } from "./header";
import { Navigation } from "./navbar";

export function Layout() {
  const { isMobile } = useScreenDetector();
  const [opened, setOpened] = useState<boolean>(false);

  return (
    <AppShell
      padding="md"
      header={{ height: { base: 60, sm: 70, md: 80 } }}
      navbar={{ width: { lg: 120 }, breakpoint: "lg", collapsed: { mobile: !opened } }}
      styles={(_theme) => ({
        main: { backgroundColor: "transparent" },
      })}>
      <Header opened={opened} setOpened={setOpened} />
      {opened || !isMobile ? <Navigation opened={opened} setOpened={setOpened} /> : null}
      <AppShell.Main>
        <Outlet />
      </AppShell.Main>
    </AppShell>
  );
}

type PageProps = {
  title: string | JSX.Element;
  subTitle?: string | JSX.Element;
  extra?: React.ReactNode[];
  children: React.ReactNode;
  breadcrumb?: { to?: string; label: string }[];
  bgColor?: string;
};

export function PageLayout({ title, subTitle, breadcrumb, bgColor = "white", extra = [], children }: PageProps) {
  const { classes } = useStyles();

  return (
    <div
      className={classes.pageLayout}
      style={{
        backgroundColor: bgColor,
      }}>
      {breadcrumb && <Breadcrumb data={breadcrumb} />}

      <div className={classes.titleLayout}>
        <div>
          <h2 className={classes.title}>{title}</h2>
          <p className={classes.subTitle}>{subTitle}</p>
        </div>
        <div className={classes.extra}>{extra}</div>
      </div>
      <div className={classes.content}>{children}</div>
    </div>
  );
}

type FormProps = {
  title: string | JSX.Element;
  subTitle?: string | JSX.Element;
  extra?: React.ReactNode[];
  children: React.ReactNode;
  bgColor?: string;
  my?: number | string;
};

export function FormLayout({ title, subTitle, bgColor = "white", extra = [], children, my = 10 }: FormProps) {
  const { classes } = useStyles();

  return (
    <Box
      className={classes.formLayout}
      my={my}
      style={{
        backgroundColor: bgColor,
      }}>
      <div className={classes.titleLayout}>
        <div>
          <h2 className={classes.title}>{title}</h2>
          <p className={classes.subTitle}>{subTitle}</p>
        </div>
        <div className={classes.extra}>{extra}</div>
      </div>
      <div className={classes.content}>{children}</div>
    </Box>
  );
}

type FilterProps = {
  left?: JSX.Element[];
  right?: JSX.Element[];
};

export function PageFilter({ left, right }: FilterProps) {
  const { classes } = useStyles();

  return (
    <div className={classes.pageFilter}>
      <div className={classes.sectionElement}>{left}</div>
      <div className={classes.sectionElement}>{right}</div>
    </div>
  );
}

const useStyles = createStyles((theme) => ({
  pageLayout: {
    display: "flex",
    flexDirection: "column",
    gap: 20,
    padding: theme.spacing.md,
    backgroundColor: theme.white,
    height: "100%",
  },
  formLayout: {
    display: "flex",
    flexDirection: "column",
    gap: 20,
    backgroundColor: theme.white,
    height: "100%",
  },
  titleLayout: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  content: {
    display: "block",
  },
  title: {
    fontSize: 18,
    fontWeight: 600,
    margin: 0,
  },
  subTitle: {
    fontSize: 14,
    margin: 0,
    padding: 0,
    color: theme.colors.gray[6],
  },
  extra: {
    display: "flex",
    gap: 15,
  },
  pageFilter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: theme.colors.gray[0],
    padding: 8,
    borderRadius: 2,
  },
  sectionElement: {
    display: "flex",
    gap: 15,
  },
}));
